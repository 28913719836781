


















































































import Vue from 'vue'
import DashboardCard from '@/components/DashboardCard.vue'
import Product from '../../../../store/models/Product'
import Account, { AccountType } from '../../../../store/models/Account'
import { mapActions, mapGetters } from 'vuex'
import CreditLine from '../../../../store/models/CreditLine'
import { DispatchingCredit } from '../../../../store/models/DispatchingCredit'
import Reimbursement from '../../../../store/models/Reimbursement'
import { AUTH_ACCESS_TOKEN_KEY } from '@/constants'

interface ComponentData {
  balanceV1: {
    default: number
    refund: number
    interest: number
  }
  title: string
  showProductList: boolean
  product: {}
  productSelected: string
  totalSfdCreditAmount: number
  totalBeneficiaryCreditAmount: number
  totalBeneficiary: number
  reimbursementRate: number
}

export default Vue.extend({
  data(): ComponentData {
    return {
      balanceV1: {
        default: 0,
        refund: 0,
        interest: 0
      },
      showProductList: false,
      title: '',
      productSelected: '',
      product: '',
      totalSfdCreditAmount: 0,
      totalBeneficiaryCreditAmount: 0,
      totalBeneficiary: 0,
      reimbursementRate: 0
    }
  },
  components: {
    DashboardCard
  },

  watch: {
    product: function (value) {
      this.updateStat(value.id)
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    products(): Array<Product> {
      return Product.all()
    },
    productsInSelect(): Array<any> {
      return [
        {
          name: 'Tous les produits'
        },
        {
          name: 'Microcrédit Alafia V1',
          id: 'alafiaV1'
        },
        ...this.products
      ]
    },
    getProductsDetail(): any {
      const allProducts = Product.query()
        .orderBy('name')
        .all()
      const productDetailList = allProducts.map((product: Product) => {
        const accountProducts = this.getProductAccount(product.id)
        return {
          id: product.id,
          nom: product.name,
          url: product.url,
          soldeRembourssement: this.soldeRembourssement(accountProducts),
          soldeDecaissement: this.soldeDecaissement(accountProducts),
          soldeFrais: this.soldeFrais(accountProducts)
        }
      })
      productDetailList.unshift({
        id: '0',
        nom: 'Microcrédit Alafia V1',
        url: '',
        soldeRembourssement: !this.balanceV1?.refund
          ? 0
          : this.balanceV1.refund,
        soldeDecaissement: !this.balanceV1?.default ? 0 : this.balanceV1.default,
        soldeFrais: !this.balanceV1?.interest ? 0 : this.balanceV1.interest
      })
      return productDetailList
    }
  },

  methods: {
    ...mapActions(['fetchAccountBalancesV1']),
    onGotoV1Click() {
      window.location.href =
        process.env.VUE_APP_V1_BASE_URL +
        '?t=' +
        sessionStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
    },

    productClick(item: any, i: any) {
      if (i == 0) this.onGotoV1Click()
      else {
        console.log({ item })

        if (item.url?.length > 0) {
          window.location.href =
            item.url + '?t=' + sessionStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
        } else {
          this.$router.push({
            name: 'dashboard',
            params: { productId: item.id }
          })
        }
      }
    },
    getProductAccount(productId: string): Array<Account> {
      return Account.query()
        .where((account: Account) => {
          return (
            account.owner == this.user.organizationId &&
            account.productId == productId
          )
        })
        .get()
    },
    soldeDecaissement(accounts: Array<Account>) {
      const moovAccount = accounts.find(value => {
        return value.type == AccountType.DISBURSEMENT && value.otm == 'moov'
      })
      const mtnAccount = accounts.find(value => {
        return value.type == AccountType.DISBURSEMENT && value.otm == 'mtn'
      })
      if (moovAccount && mtnAccount)
        return moovAccount.balance + mtnAccount.balance
      return 0
    },
    soldeRembourssement(accounts: Array<Account>) {
      const moovAccount = accounts.find(value => {
        return value.type == AccountType.REFUND && value.otm == 'moov'
      })

      const mtnAccount = accounts.find(value => {
        return value.type == AccountType.REFUND && value.otm == 'mtn'
      })

      if (moovAccount && mtnAccount)
        return moovAccount.balance + mtnAccount.balance
      return 0
    },
    soldeFrais(accounts: Array<Account>) {
      const moovAccount = accounts.find(value => {
        return value.type == AccountType.FEES && value.otm == 'moov'
      })
      const mtnAccount = accounts.find(value => {
        return value.type == AccountType.FEES && value.otm == 'mtn'
      })

      if (moovAccount && mtnAccount)
        return moovAccount.balance + mtnAccount.balance
      return 0
    },
    updateStat(productId: any) {
      CreditLine.api()
        .sfdTotalCreditAmount(productId)
        .then((value: any) => {
          this.totalSfdCreditAmount = value.response.data
        })
        .catch((err: Error) => {
          this.totalSfdCreditAmount = 0
        })

      DispatchingCredit.api()
        .totalBeneficiaryAmount(productId)
        .then((value: any) => {
          this.totalBeneficiaryCreditAmount = value.response.data.totalAmount
          this.totalBeneficiary = value.response.data.nbBeneficiaries
        })
        .catch((err: Error) => {
          this.totalBeneficiaryCreditAmount = 0
          this.totalBeneficiary = 0
        })

      Reimbursement.api()
        .reimbursementRate(productId)
        .then((value: any) => {
          this.reimbursementRate = value.response.data
        })
        .catch((err: Error) => {
          this.reimbursementRate = 0
        })
    }
  },
  async mounted() {
    Product.api().fetchAll()
    Account.api().fetchAll()
    this.product = this.productsInSelect[0]
    this.fetchAccountBalancesV1().then((response: any) => {
      if (response.status == '200') this.balanceV1 = response.data
    })
  }
})
